import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { View, Button, MenuItem, Icon, Text, Styles, Menu } from 'primase';

// Components
import { VisuallyHidden } from 'ariakit';
import { useLocation, useHistory } from 'react-router-dom';

// Helpers
import { PAGES } from '../../constants/pages';
import { useAccount } from '../../config/account';

// Styles
import { styles } from './styles';
import Link from '../link';

interface IMenuState {
  anchorEl?: EventTarget | null;
  open: boolean;
}

interface IProfileMenuProps {
  children?: React.ReactNode;
}

const SETTINGS_PAGE = `${PAGES.MY}${PAGES.SETTINGS}`;

const ProfileMenu = ({ children = null }: IProfileMenuProps) => {
  const { isAuthenticated, logout, redirectToLogin, user } = useAccount();
  const { t } = useTranslation();
  const [menuState, setMenuState] = React.useState<IMenuState>({
    anchorEl: null,
    open: false,
  });

  const { pathname } = useLocation();
  const pathnameRef = useRef(pathname);
  const history = useHistory();

  const { firstName = '', lastName = '' } = user || {};

  const handleMenuClick = React.useCallback((event: Event) => {
    setMenuState({
      anchorEl: event.currentTarget,
      open: true,
    });
  }, []);

  const handleLinkClick = (href: string) => {
    history.push(href);
  };

  const handleLogout = async () => {
    await logout();
    redirectToLogin();
  };

  // Clear any existing error pathname change
  if (pathnameRef.current !== pathname && menuState.open) {
    setMenuState({ open: !menuState.open });
    pathnameRef.current = pathname;
  }

  if (!isAuthenticated()) {
    return null;
  }

  return (
    <View>
      <MediaQuery maxWidth={Styles.layout.breakpoints.md}>
        <Button
          light
          onClick={handleMenuClick}
          aria-expanded={menuState.open}
          style={{ padding: '5px 5px' }}
        >
          <Icon data-testid="mobile-profile-menu" classes={styles.icon}>
            menu
          </Icon>
          <VisuallyHidden>{t('labels.navigationMenu')}</VisuallyHidden>
        </Button>
      </MediaQuery>
      <MediaQuery minWidth={Styles.layout.breakpoints.md}>
        <Button
          secondary
          small
          css={styles.profileWrapper}
          onClick={handleMenuClick}
          aria-expanded={menuState.open}
        >
          <Text>
            {firstName} {lastName}
          </Text>
          <Icon classes={styles.profile}>
            {menuState.open ? 'chevron-up' : 'chevron-down'}
          </Icon>
        </Button>
      </MediaQuery>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="popover-menu"
        data-testid="dropdown-popover-menu"
        anchorEl={menuState.anchorEl}
        open={menuState.open}
        variant="menu"
        onClose={() => setMenuState({ open: false })}
      >
        {children}
        <MenuItem onClick={() => handleLinkClick(SETTINGS_PAGE)} disableGutters>
          <Link to={SETTINGS_PAGE} styles={styles.menuLink}>
            <Text>{t('pages.settings')}</Text>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout} disableGutters>
          <View style={styles.menuLink}>
            <Text>{t('labels.signOut')}</Text>
          </View>
        </MenuItem>
      </Menu>
    </View>
  );
};

export default ProfileMenu;
