import { ReactNode } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useAccount } from '../../config/account';

import { PAGES } from '../../constants/pages';
import BaseRedirect from '../base-redirect';

type PrivateRouteProps = {
  children: ReactNode;
  roles: string[];
  path: string;
  exact?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrivateRoute = ({
  children,
  exact = false,
  ...rest
}: PrivateRouteProps) => {
  const { isAuthenticated } = useAccount();
  const location = useLocation();

  return (
    <Route
      {...rest}
      exact={exact}
      render={() => {
        if (isAuthenticated()) {
          return children;
        }

        return (
          <BaseRedirect
            to={{
              pathname: `${PAGES.ACCOUNT}${PAGES.LOGIN}`,
              state: { from: location.pathname },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
