import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

// Components
import Root from './features/root';

// Config
import { AccountProvider } from './config/account';
import { client } from './config/apollo';
import './config/sentry';

// Helpers
import * as serviceWorker from './serviceWorker';

// Translation
import './i18n';

// Styles
import 'primase/build/main.css';
import 'normalize.css';
import './assets/fonts/lato.css';
import './assets/styles/base.scss';

const rootElement = document.getElementById('root');

function renderApp() {
  if (rootElement?.hasChildNodes()) {
    hydrateRoot(
      rootElement,
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AccountProvider>
            <Root />
          </AccountProvider>
        </BrowserRouter>
      </ApolloProvider>,
    );
  } else if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AccountProvider>
            <Root />
          </AccountProvider>
        </BrowserRouter>
      </ApolloProvider>,
    );
  }
}

if (process.env.NODE_ENV === 'development') {
  // Comment this to disable the Mock Service Worker
  import('./mocks/browser').then(({ worker }) =>
    worker.start().then(renderApp),
  );
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Comment this line out for MSW to work in Safari
serviceWorker.unregister();
