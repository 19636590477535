import { Animate, GetInterpolator } from 'react-move';
import { easeQuadOut } from 'd3-ease';
import { interpolate, interpolateTransformSvg } from 'd3-interpolate';

export const interpolation: GetInterpolator = (begValue, endValue, attr) => {
  if (attr === 'transform') {
    return interpolateTransformSvg(begValue, endValue);
  }

  return interpolate(begValue, endValue);
};

type FadeInWrapperProps = { show?: boolean; children?: React.ReactNode };

const FadeInWrapper: React.FC<FadeInWrapperProps> = ({
  show = true,
  children,
}) => (
  <Animate
    show={show}
    start={{ opacity: 1 }}
    enter={{
      opacity: [1],
      timing: { duration: 200, delay: 100, ease: easeQuadOut },
    }}
    update={{
      opacity: [1],
      timing: { duration: 200, ease: easeQuadOut, delay: 100 },
    }}
    leave={[
      {
        opacity: [0],
        timing: { delay: 0, duration: 200, ease: easeQuadOut },
      },
    ]}
    interpolation={interpolation}
  >
    {({ opacity }) => <div style={{ opacity }}>{children}</div>}
  </Animate>
);

export default FadeInWrapper;
