import { ReactNode } from 'react';
import { Navigation, View } from 'primase';
import { useTranslation } from 'react-i18next';

// Components
import Link, { A } from '../link';

// Styles
import { styles } from './styles';

// Images
import logo from '../../assets/images/myriad_V_horiz.png';

// Constants
import { PAGES } from '../../constants/pages';

interface IAppHeaderSimpleProps {
  children?: ReactNode;
  logoUrl?: string;
}

const AppHeaderSimple = ({
  children = null,
  logoUrl,
}: IAppHeaderSimpleProps) => {
  const { t } = useTranslation();

  const logoWrapper = (
    <View classes={styles.logoWrapper}>
      <img alt={t('labels.headLogoAltText')} src={logo} style={styles.logo} />
    </View>
  );

  const prefix = logoUrl ? (
    <A to={logoUrl || PAGES.BASE}>{logoWrapper}</A>
  ) : (
    <Link to={PAGES.MY}>{logoWrapper}</Link>
  );

  return <Navigation suffix={children} prefix={prefix} />;
};

export default AppHeaderSimple;
